.Home {
  text-align: center;
  width: 100vw;
  height: 100vh;
  color:rgb(255, 255, 255);
  font-size: 20px;
  display: flex;
  overflow: hidden;
}

.Primary {
  flex: 1;
  background-image: url("../public/Assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  font-family: 'co-arabic';
  font-weight: 100;
  z-index: 1;
}


.scribble-button {
  background-image: url('../public/Assets/button-white.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #00000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  padding: 10px 20px;
  border: none;
  color: #093C5D;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.scribble-button.green {
  background-image: url('../public/Assets/button-green.png');
  color: white;
}

.scribble-button.blue {
  background-image: url('../public/Assets/button-blue.png');
  color: white;
}

.scribble-button:active {
  transform: scale(0.9);
  transition: transform 1s ease-out;
}

.scribble-button:hover {
  background-image: url('../public/Assets/button-active.png');
}

.scribble-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.scribble-button:disabled:hover {
  transform: scale(1);
}

.button {
  background-color: #093C5D;
  transition: all 0.2s;
  width: 150px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  margin: 10px;
  font-size: 20px;
  font-family: co-arabic;
  cursor: pointer;
}

.button.small-text{
  font-size: 15px;
}

.button.icon {
  height: 60px;
  width: 80px;
  border-radius: 30px;
}

.button.text {
  font-size: 25px;
  margin: 0;
  height: auto;
  width: auto;
  background-color: transparent;
  color:#093C5D;
  box-shadow: none;
}

.button.text:hover {
  background-color: transparent;
  color: #3391CE;
}

.button.light {
  background-color: #ffffff;
  color: #093C5D;
  font-weight: bold;
}

.button.graphic {
  background-color: transparent;
  width: 10%;
  height: 10%;
  border: none;
  box-shadow: none;
}

.button.graphic.nav {
  width: 100px;
  alt: "nav";
}

.button:hover {
  background-color: #3391CE;
}

.button.light:hover {
  color: #3391CE;
  background-color: white;
}

.button.graphic:hover {
  background-color: transparent;
  transform: scale(1.1);
}

button.graphic:not(:active):hover {
  transition-delay: 2s;
}


.button.small {
  width: 100px;
  height: 30px;
  font-size: 15px;
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}

.button:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.button.graphic:active{
  transform: scale(0.9) translateY(4px);
}

.button:disabled:hover {
  background-color: #093C5D;
}

.button.light:disabled:hover {
  background-color: white;
  color: #093C5D;
}

.button:disabled:active {
  transform: none;
  box-shadow: none;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
}

.container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.container-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.sidemenu {
  position: relative;
  background: -webkit-linear-gradient(top,  #093C5D 20%,#1CA287 45%, #1CA287 55%,#093C5D 80%, black 99%);;
  box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.75);
  width: 25vw;
  height: 100vh;
  color: rgb(0, 0, 0);
  z-index: 3;
}

.sidemenu.hold {
  min-width: 500px;
}

.side-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  height: 10vh;
}

.header-text {
  font-size: 30px;
  font-weight: bold;
  font-family: tragic marker;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.module-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  background: white;
  z-index: 10;
  border: solid black 1px;
}


.side-menu-body{
  flex-direction: column;
  height: 90vh;
}

.side-menu-footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
}

.side-menu-footer-link {
  color: white;
  font-size: 15px;
  cursor: pointer;
}

.side-menu-footer-link:hover {
  color: #BFBFBF;
}

.side-menu-signout:hover {
  color: #BFBFBF;
}

.module-header{
  background-color: #093C5D;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 500;
  padding: 20px;
  border-bottom: solid white;
  box-shadow: 0px -2px 6px 0px rgba(0,0,0,0.75);
}

.module-description {
  font-size: 20px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.module-lowerbody {
  padding-top: 100px;
}

.sub-header {
  font-size: 30px;
  text-align: left;
  padding-left: 20px;
}

.description {
  padding-left: 20px;
  padding-right: 10px;

}

.module-ui{
  display: flex;
  justify-content: space-around;
  padding:15px;
  color: white;
  width: 100%;
}

.module-user-input{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 100%;
}

.form-button {
  padding: 10px;
  margin: 25px;
  width: 100px;
  background-color: none;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.popup-success {
  z-index: 6;
}

.popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000bb;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

}

.popup-mask.standard {
  position: absolute;
  width: 100%;
  height: 100%;
}

.popup {
  width: 80%;
  height: 80%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: black 1px solid;
  border-radius: 20px;
  padding: 20px;
  color: black;
}

.popup.medium {
  width: 50%;
  height: 50%;
  display: flex;
}

.popup.small {
  width: 600px;
  height: 200px;
}

.popup-whiteboard {
  background-color: white;
  border-radius: 20px;
  height: 750px;
  aspect-ratio: 1/.6;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.popup-image {
  background-image: url('../public/Assets/popup-img.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 700px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.popup-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: #093C5D 2px solid;
  border-radius: 20px;
  padding: 20px;
  color: black;
}

.popup-inner.message {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  width: 500px;
  height: 100px;
}

.popup-top {
  margin-bottom: 20px;
  position: absolute;
  top: 0; left: 0;
  height: 80%;
  width: 100%;
}

.popup-textarea {
  position: relative;
  top: 1%; left: 0%;
  width: 90%;
  height: 90%;
  resize: none;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 5px;
  border: black 1px solid;
  margin: 12px;
  font-family: co-arabic;
  font-size: 20px;
  line-height: 50px;
  position: bottom;
  outline: none;
  box-shadow: 0 0 15px 0 rgba(235, 235, 235, 0.2);
  color: rgb(8, 8, 8)
}

.popup-textarea::-webkit-scrollbar {
  display:none;
}

.popup-bottom {
  position: absolute;
  bottom: 0; left: 25%;
  height: 10%;
  width: 50%;
  border: none;
  display: flex;
  justify-content: space-around;
  padding: 0px;
}

.table {
  display: flex;
  width: 100%;
  padding: 10px;
}

.table-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.table-cell {
  text-align: center;
  font-size: 20px;
  padding: 10px;
  width: 600px;
}

.table-cell.id {
  width: 100px;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  margin: 8px 0;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #1CA287;
  margin-top: -8px;
}

input[type='range']::-moz-range-track {
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

input[type='range']::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #4caf50;
}

output {
  display: block;
  margin-top: 8px;
  text-align: center;
  font-size: 16px;
}

.upload-section{
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.text-upload-area{
  border: solid 2px #093C5D;
  border-radius: 10px;
  font-family: co-arabic;
  font-size: 20px;
  color: #000;
  resize: none;
  margin: auto;
  width: 95%;
  height: 60%;
  padding: 10px;
  line-height: 22px;
  outline: none;
}

.progress-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 150px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}

.stepdot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #093C5D
}

.stepdot.completed {
}

.stepdot.next {
  background-color: gray;
}

.pulsing {
  animation: pulsing 1s infinite;
  background-color: #1CA287;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.line {
  height: 62px;
  width: 1px;
  margin-bottom: 10px;
}

.line.completed {
  border: solid 1px #1CA287;
}

.line.next {
  border: solid 1px gray;
}

.step-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  line-height: 95px;
}

.step-label {
  font-size: 30px;
  font-weight: regular;
  margin-bottom: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

@font-face {
  font-family: 'tragic marker';
  src: url('TragicMarker.otf') format('opentype');
}

.tooltip{
  position: absolute;
  bottom: 90%;
  visibility: hidden;
  background-color: #1CA287;
  color: white;
  border: solid white 2px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 5 10px 0 rgba(82, 82, 82, 0.706);
}

.select:hover .tooltip{
  visibility: visible;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.processing-animation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.processing-ellipse {
  width: 50px;
  height: 50px;
  background-color: #1CA287;
  border-radius: 50%;
  margin: 0 5px;
  animation: pulsing 1s linear infinite;
}

.processing-ellipse:nth-child(2) {
  animation-delay: 0.5s;
}

.main-menu {
  position: absolute;
  left: 10px;
  top: 10vh;
  width: 250px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.main-menu-item {
  width: 100%;
  cursor: pointer;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
}

.main-menu-item:hover {
  background-color: #cfcfcf;
}

.meatballMenu {
  width: 75px;
  height: 50px;
  background-image: url('../public/Assets/meatball-menu.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.skewerMenu {
  width: 100%;
  height: 100%;
  background-image: url('../public/Assets/skewer-menu.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.main-menu.skewerRight {
  left: 0;
}

.main-menu.meatballLeft {
  top: 10px;
  left: 150px;
  height: 75px;
  width: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  box-shadow: none;
}

strong {
  font-weight: bold; /* This is usually default for strong, but added for clarity */
}

em {
  text-decoration: underline;
  font-style: normal;
}

