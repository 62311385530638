.standard-main {
    height: 100vh;
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.standard-whiteboard-magnet {
    position: absolute;
    background-image: url("../../public/Assets/whiteboard-button.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 5%;
    aspect-ratio: 1/1;
    margin: 1%;
}

.standard-whiteboard-magnet.tl {
    top: 0; left: 0;
}

.standard-whiteboard-magnet.tr {
    top: 0; right: 0;
}

.standard-whiteboard-magnet.bl {
    bottom: 0; left: 0;
}

.standard-whiteboard-magnet.br {
    bottom: 0; right: 0;
}

.standard-box {
    height: 75vh;
    margin: 0% 3% 3% 3%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 3px 10px 3px rgba(0, 0, 0, 0.467);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    font-family: tragic marker;
    font-size: 30px;
    color: #093C5D;
}

.standard-box.header {
    position: relative;
    height: 10vh;
    margin: 3% 3% 0% 3%;
    background-color: #093C5D;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: co-arabic;
    font-size: 25px;
}

.standard-floating-header {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.standard-floating-header-background {
  background-image: url("../../public/Assets/title-background-blue.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  color: white;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.standard-floating-title {
  color: #093C5D;
  font-size: 50px;
  width: 60%;
  height: 100%;
}

.standard-floating-menu-container {
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  position: relative;
}

.standard-floating-menu-container.left {
  justify-content: flex-start;
  padding-left: 50px;
}

.standard-floating-menu-container.right {
  justify-content: flex-end;
  padding-right: 50px;
}

.standard-box-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40%;
}

.standard-button-container {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.standard-menu-container {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.standard-divider {
    background-image: url("../../public/Assets/divider.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 3%;
    width: 100%;
}

.standard-divider.long {
  background-image: url("../../public/Assets/divider-long.png");
  width: 90%;
}

.standard-divider.short {
  background-image: url("../../public/Assets/divider-short.png");
  width: 80%;
}

.standard-label {
    background-image: url("../../public/Assets/divider-short.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    font-size: 35px;
    padding: 10px;
}

.tooltip-box::before { 
    display: none;
    background-color: #1CA287;
    color: white;
    height: 100%;
    width: 30vw;
    border-radius: 5px;
    font-family: co-arabic;
    font-size: 15px;
    position: absolute;
    top: 0; 
    right: 0;
    z-index: 10;
  }

.standard-info-graphic {
  background-image: url('../../public/Assets/info-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50%;
  aspect-ratio: 1 / 1;
  position: absolute;
  right: 5%;
  z-index: 2;
}

.standard-info-graphic:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.standard-info-graphic.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.standard-info-alert {
  position: absolute;
  right: 3%;
  height: 75%;
  width: 20%;
  border: solid white 4px;
  border-radius: 10px;
  background-color: #1CA287;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}

.standard-info-alert.hidden {
  display: none;
}

.standard-info-alert:hover {
  cursor: pointer;
}

.standard-menu-button {
  background-color: #093C5D;
  height: 50px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: solid white 3px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  z-index: 3;
}

.standard-menu-button:hover {
  background-color: #345f7b;
}

.standard-dropdown-menu-title {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}

.standard-dropdown-menu-box {
  border-radius: 10px;
  background-color: white;
  box-shadow: 2px 3px 10px 3px rgba(0, 0, 0, 0.467);
  min-height: 200px;
  width: 200px;
  position: absolute;
  top: 20%; right: 10px;
  z-index: 2;
}

.standard-menu-spacer {
  height: 75px;
  width: 100%;
}

.standard-menu-item {
  height: 40px;
  width: 100%;
  color: #093C5D;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}

.standard-menu-item:hover {
  background-color: #093C5D;
  color: white;
  cursor: pointer;
}

.standard-menu-item.disabled {
  color: #9e9e9e;
  cursor: default;
}

.standard-dropdown-menu-graphic {
  background-image: url('../../public/Assets/Dropdown-menu.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 75%;
  aspect-ratio: 1 / 1;
}

.standard-dropdown-menu-graphic.rotated {
  transform: rotate(180deg);
}

.standard-graphic-animation {
    background-image: url('../../public/Assets/graph-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    z-index: 2;
    height: 40vh;
    aspect-ratio: 1 / 1;
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
  }
  
  .standard-graphic-animation.processing {
    animation-name: spin;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  @keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .standard-guide-instructions-box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    white-space: pre-line;
    overflow-y: auto;
  }

  .standard-guide-instructions-title {
    font-size: 30px;
  }

  .standard-guide-instructions-header {
    font-weight: bold;
    font-size: 25px;
  }

  .standard-guide-instructions-lineitem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .standard-guide-instructions-bullet {
    background-image: url('../../public/Assets/Bullet_black.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 15px;
    aspect-ratio: 1 / 1;
    margin-top: 8px;
  }

  .standard-guide-instructions-text {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    text-align: left;
  }

  .whiteboard-header {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: bold;
  }
  
  .whiteboard-section{
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .whiteboard-section.half {
    height: 40%;
    overflow: hidden;
  }
  
  .whiteboard-block {
    height: 100%;
    width: 25%;
    transition: transform 0.1s ease-in-out;
    margin: 10px;
  }
  
  .whiteboard-block-header {
    background-image: url("./assets/wb-label-green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    color: white;
    height: 23%;
    font-family: co-arabic;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .whiteboard-block-header.red {
    background-image: url("./assets/wb-label-red.png");
  }

  .whiteboard-block-header.blue {
    background-image: url("./assets/wb-label-blue.png");
  }
  
  .whiteboard-block-content {
    text-align: left;
    white-space: pre-line;
    height: 70%;
    width: 90%;
    font-family: patrick hand;
    font-size: 18px;
    line-height: 20px;
    padding: 0 3% 0 5%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 5% 5% 0 5%;
    position: relative;
  }

  .whiteboard-block-content:hover{
    cursor: pointer;
    background: linear-gradient(to bottom, transparent, rgba(111, 128, 140, 0.224), transparent);
    border-radius: 5px;
  }
  
  .whiteboard-block-content::-webkit-scrollbar {
    width: 4px;
  }
  
  .whiteboard-block-content::-webkit-scrollbar-track {
    background-color: #c5c5c500;
  }
  
  .whiteboard-block-content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #364a577f;
  }
  
  .whiteboard-button {
    background: none;
    border: none;
    color: black;
    font-size: 20px;
    font-family: permanent marker;
    position: absolute;
    top: 0; left: 0;
    cursor: pointer;
  }
  
  .detailed-block-main {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detailed-block-header{
    font-weight: bold;
    font-size: 50px;
    width: 80%;
    position: relative;
  }
  
  .detailed-block-content{
    height: 85%;
    width: calc(100% - 100px);
    margin: 50px;
    font-size: 20px;
    font-family: patrick hand;
    text-align: left;
    white-space: pre-line;
    overflow: scroll;
  }
  
  .detailed-block-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .detailed-block-content::-webkit-scrollbar-track {
    background-color: #c5c5c500;
  }
  
  .detailed-block-content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #093b5d00;
  }

  .detailed-block-textarea {
    height: 100%;
    width: 100%;
    resize: none;
  }
  
  .block-back-button{
    background-color: transparent;
    border: none;
    font-family: tragic marker;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 2%; right: 5%;
  }
  
  .block-back-button:hover{
    color: #9e9e9e;
  }

  .copy-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .copy-button:active {
    transform: scale(0.95);
  }
