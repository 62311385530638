.nextsteps-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 90%;
  width: 90%;
  color: #093C5D;
}

.nextsteps-header {
  font-family: Tragic marker;
  font-size: 50px;
}

.nextsteps-header-sub {
  font-size: 20px;
  line-height: 25px;
  width: 100%;
}

.nextsteps-sub-heading {
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.nextsteps-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nextsteps-sub-container {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  width: 100%;
}

.nextsteps-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px;
}

.nextsteps-section-leftside {
  text-align: left;
  width: 98%;
}

.nextsteps-section-rightside {
  width: 30%;
  display: flex;
  justify-content: center;
}
