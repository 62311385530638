.home {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #093C5D;
}

.home-section {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-section-title {
    font-size: 50px;
    font-family: co-arabic;
}

.home-app {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    position: relative;
}

.home-app-tile {
    height: 100px;
    width: 100px;
    border-radius: 20px;
    background-color: #093C5D;
    box-shadow: 5px 5px 5px #888888;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-app-tile:hover {
    cursor: pointer;
}

.home-app-tile.noAccess {
    background-color: #90a6a2;
    box-shadow: none;
    pointer-events: none
}

.home-requestAccess-link {
    color: #3391CE;
    cursor: pointer;
}

.home-sidepanel-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.home-sidepanel-header {
    font-size: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.home-sidepanel-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 25px;
}

.home-sidepanel-section-item {
    font-size: 20px;
    text-align: left;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
}

.home-sidepanel-section-item:hover {
    text-decoration: underline;
}

.home-sidepanel-section-instructions {
    color: black;
    font-size: 15px;
    white-space: pre-line;
    max-width: 350px;
    text-align: left;
}


.home-sidepanel-access-codes {
    margin: 5px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.home-sidepanel-access-code {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: co-arabic;
    font-size: 20px;
    color: #093C5D;
    width: 300px;
    border: solid black 2px;
    margin-right: 15px;
    background-color: white;
}