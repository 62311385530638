.new-user-start-main {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;
}

.new-user-section {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-user-subsection {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    margin: 0px;
}

.new-user-subsection.choice {
    height: 40%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.new-user-subsection.create {
    height: 80%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.new-user-subsection.verify {
    height: 40%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.new-user-subsection.divider {
    align-items: center;
    height: 5%;
}

.new-user-subsection.upperright {
    text-align: left;
    padding-left: 15px;
    width: 50%;
    height: 40%;
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
}

.new-user-subsection.lowerright{
    text-align: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 5px black;
    height: 20%;
    font-size: 30px;
    padding: 50px;
}

.new-user-subheader {
    color:#093C5D;
    font-size: 30px;
    font-weight: bold;
    width: 80%;
}

.new-user-subtext {
    display: flex;
    justify-content: center;
    font-family: patrick hand;
    font-size: 25px;
    line-height: 30px;
    margin: 15px;
    width: 80%;
}

.new-user-option-container {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.new-user-option-container.vertical {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
}

.new-user-option-container.center {
    justify-content: center;
    align-items: center;
}

.new-user-create-input {
    border: solid #093C5D;
    outline: none;
    width: 400px;
    margin-top: 5px;
}

.new-user-create-input.valid {
    border: solid #1CA287;
    background-color: #1ca2873d;
}

.profile-company-loading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: solid black 1px;
}

.profile-company-message {
    margin: 10%;
    font-size: 30px;
}

.new-user-choice-slider {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    height: 10%;
    width: 50%;
    background-color: #1CA287;
    border-radius: 10px;
    border: solid black 1px;
}

.new-user-slider-button {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    height: 88%;
    color: rgb(217, 216, 216);
}

.new-user-slider-button:hover {
    cursor: pointer;
    color: white;
}

.new-user-slider-button.selected {
    background-color: #093C5D;
    border: solid black 1px;
    box-shadow: 0 0 5px black;
    color: white;
}

.calendly {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 100;
}
