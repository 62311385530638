.strategy-whiteboard {
    height: 90vh;
    margin: 3% 3% 3% 3%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 3px 10px 3px rgba(0, 0, 0, 0.467);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    font-family: tragic marker;
    font-size: 30px;
    color: #093C5D;
}

.strategy-whiteboard-main {
    height: 100%;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.strategy-whiteboard-header {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Permanent marker;
    font-size: 50px;
}

.strategy-whiteboard-section {
    height: 38%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
}

.detailed-block-body {
    height: 100%;
    display: flex;
  }
  
  .detailed-block-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .detailed-block-instructions {
    font-size: 30px;
    margin: 5% 
  }



  .swb-sidepanel-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.swb-sidepanel-header {
    font-size: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.swb-sidepanel-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 25px;
}

.swb-sidepanel-section-item {
    font-size: 25px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.large-swb-block-header {
    height: 100px;
    width: 90%;
    align-self: center;
    justify-self: center;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.swb-block-title {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-family: co-arabic;
    font-size: calc(2vw + 2vh);
    font-weight: bold;
    color: white;
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.swb-block-title.blue {
    background-image: url("../assets/wb-label-long-blue.png");
}

.swb-block-title.green {
    background-image: url("../assets/wb-label-long-green.png");
}

.swb-block-title.red {
    background-image: url("../assets/wb-label-long-red.png");
}

.swb-block-back-arrow {
    background-image: url("../assets/back-arrow-button.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 60px;
    width: 10%;
    aspect-ratio: 1.2/1;
    cursor: pointer;
}

.swb-block-menu-container {
    width: 10%;
    height: 60px;
    position: relative;
}

.swb-block-menu-box {
    background-color: white;
    height: auto;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0; right: calc(50% + 25px);
}

.swb-block-menu-item {
    height: 50px;
    width: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: co-arabic;
    font-size: 20px;
    cursor: pointer;
    margin: 5px;
}

.swb-block-menu-item:hover {
    background-color: #093b5db0;
    color: white;
}

.large-swb-block-elements-container {
    overflow: auto;
    height: calc(100% - 200px)
}

.large-swb-block-elements-container::-webkit-scrollbar {
    width: 4px;
}
  
.large-swb-block-elements-container::-webkit-scrollbar-track {
    background-color: #c5c5c500;
}
  
.large-swb-block-elements-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #364a577f;
}

.large-swb-block-text {
    font-family: patrick hand;
    color: black;
    font-size: 25px;
    margin: 50px;
    white-space: pre-wrap;
    text-align: left;
}

.large-swb-block-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
    margin-left: 20px;
    
}

.block-element-header {
    font-family: co-arabic;
    font-weight: bold;
    font-size: 30px;
    margin-left: 25px;
}

.block-element-item {
    font-family: patrick hand;
    font-size: 25px;
    color: black;
    margin-left: 50px;
    width: calc(100vw - 35vw - 150px);
    text-align: left;
    margin-bottom: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}


.block-element-item.bullet {
    background-image: url("../assets/Bullet_black.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left;
    background-position-y: 3px;
    padding-left: 25px;
    line-height: 25px;
}

.block-element-item.saved:hover {
    cursor: pointer;
    text-decoration: underline;
}

.block-element-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.block-element-item-edit-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.element-item-button {
    border: none;
    border-radius: 5px;
    background-color: #093C5D;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: white;
    height: 30px;
    width: 70px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: co-arabic;
    font-size: 20px;
    cursor: pointer;
}

.element-item-button.delete {
    background-color: #771C1B;
}


.swb-smallblock-container {
    height: 100%;
    width: 100%;
}

.swb-smallblock-content {
    height: 100%;
    width: 100%;
}

.swb-smallblock-element {
    margin-bottom: 10px;
    width: 100%;
    position: relative;
}

.swb-smallblock-element-name {
    font-family: co-arabic;
    font-size: 20px;
    font-weight: bold;
}

.swb-smallblock-element-item {
    margin-left: 10px;
    margin-top: 5px;
    color: black;
    max-height: 42px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;     /* Using box model for line clamping */
    -webkit-line-clamp: 2;    /* Clamp after 2 lines */
    -webkit-box-orient: vertical;
}


.swb-smallblock-element-item.bullet {
    background-image: url("../assets/Bullet_black.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: left;
    background-position-y: 4px;
    padding-left: 17px;
}

