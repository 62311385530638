.demo-interview {
    background-image: url("../../../public/Assets/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: solid #093C5D 3px;
    border-radius: 25px;
    overflow-y: scroll;
}

.demo-interview::-webkit-scrollbar {
    display:none;
}

.demo-interview-message {
    border-radius: 15px;
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #3391CE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin-bottom: 25px;
    margin-top: 10px;
    padding: 10px;
    color: white;
}

.demo-interview-message.right {
    align-self: flex-end;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #093C5D;
}