.profile-company-details-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 55px;
    overflow: hidden;
}

.profile-header {
    width: 90%;
    height: 15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
}

.profile-header-text {
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: patrick hand;
    font-size: 60px;
}

.profile-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    font-family: co-arabic;
    font-size: 20px;
    font-weight: bold;
}

.profile-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
    margin: 50px 10px 10px 10px;
    overflow: hidden;
}

.profile-section-graphic {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 30%;
    aspect-ratio: 1/1;
    margin-bottom: 0px;
}

.profile-section-text {
    font-family: 'patrick hand';
    font-size: 20px;
    line-height: 20px;
    white-space: pre-line;
    text-align: center;
    color: black;
    width: 80%;
}

.profile-company-details {
    height: 35%;
    width: 90%;
    margin: 3% 3% 0 3%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.profile-company-details.large{
    height: 70%;
    overflow-y: scroll;
}

.profile-company-details::-webkit-scrollbar {
    width: 8px;
  }
  
  .profile-company-details::-webkit-scrollbar-track {
    background-color: #95959500;
  }
  
  .profile-company-details::-webkit-scrollbar-thumb {
    background-color: #093C5D;
  }

.profile-textarea {
    height: 100%;
    width: 100%;
    font-family: patrick hand;
    font-size: 20px;
    border: solid #093C5D 1px;
    border-radius: 5px;
    padding: 10px;
    resize: none;
}

.profile-textarea::-webkit-scrollbar {
    width: 8px;
  }
  
  .profile-textarea::-webkit-scrollbar-track {
    background-color: #95959500;
  }
  
  .profile-textarea::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #093C5D;
  }

.profile-textarea.name {
    font-size: 30px;
    padding-left: 10px;
}

.profile-success-message{
    color: #1CA287;
}

.profile-company-processing-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    border: solid black 1px;
}

.profile-processing-message {
    margin: 50px;
}

.profile-box-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.profile-box-title {
    padding-right: 20px;
}