.demo-summary {
    background-color: white;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    position: relative;
}

.demo-summary-message {
    opacity: 0;  
    transition: all 5s ease;  /* 1-second transition for all properties */
}

.demo-summary-message.show {
    opacity: 1;  
    top: 50% !important;  
    left: 50% !important;
    bottom: auto !important;
    right: auto !important;
    transform: translate(-50%, -50%);
}

.demo-summary-message.center, .demo-summary-message.tl, .demo-summary-message.tr, .demo-summary-message.br, .demo-summary-message.bl {
    position: absolute;
}

.demo-summary-message.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.demo-summary-message.tl {
    top: 20px;
    left: 20px;
}

.demo-summary-message.tr {
    top: 20px;
    right: 20px;
}

.demo-summary-message.br {
    bottom: 20px;
    right: 20px;
}

.demo-summary-message.bl {
    bottom: 20px;
    left: 20px;
}

.demo-summary-graphic {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;  
    transition: all 2s ease;  /* 1-second transition for all properties */
}

.demo-summary-graphic.show {
    opacity: 1;  
    top: 50% !important;  
    left: 50% !important;
    bottom: auto !important;
    right: auto !important;
    transform: translate(-50%, -50%);
}

.demo-summary-graphic.spinning {
    animation-name: spin;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  @keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
  }