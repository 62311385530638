
  .chat-log {
    padding: 0px;
    text-align: left;
    overflow-y: scroll;
    height: 88vh;
    display: flex;
    flex-direction: column;
  }
  
  .chat-log::-webkit-scrollbar {
    display:none;
    padding-top: 10px;
  }
  
  .chat-message {
    background-color: #3391CE;
    font-family: patrick hand;
    font-weight: lighter;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    align-self: flex-start;
    width: 70%;
    margin-top: 10px;
    box-shadow: 2px 2px 4px 2px rgba(61, 81, 100, 0.75);
  }
  .chat-message.gpt {
    background-color: #093C5D;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    align-self: flex-end;
  }
  
  
  .chat-message-center {
    max-width: 100;
    align-items: center;
    display: flex;
    padding: 15px;
    padding-left: 40px;
    padding-right: 40px;
    vertical-align: middle;
    margin-left: 1%;
    margin-right: 5%;
  }
  
  .chat-message-center.gpt {
    margin-left: 5%;
    margin-right: 1%;
    justify-content: right;
  }
  
  .avatar {
    width: 70px;
    height: 70px;
    margin-right: 25px;
    flex: none
  }
  
  .avatar.gpt {
    margin-left: 25px;
    flex: none
  }
  
  .message {
    flex: 1;
    text-align: left;

  }
  
  .message.gpt {
    text-align: left;
    white-space: pre-line;
  }
  
  
  .chat-input-holder {
    padding: 10px;
    position: absolute;
    bottom: 0; left: 0; right: 0;
    background-color: #1CA287;
    box-shadow: 2px -2px 3px 0px rgba(24, 32, 27, 0.75);
    display: flex;
    min-height: 12vh;
  }
  
  .input-and-button-container {
    background-color: #fffffffa;
    border-radius: 25px;
    box-shadow: inset 0px 3px 7px 2px #0d4b3e;
    margin-left: 5%;
    margin-right: 2%;
    padding: 5px;
    position: bottom;
    color: #093C5D;
    display: flex;
    align-items: center;
    flex: 1;
  }
  
  .chat-input-area {
    flex: 1;
    display: flex;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
    border: none;
    font-family: patrick hand;
    font-size: 25px;
    line-height: 30px;
    margin-left: 15px;
    overflow: vertical;
  }
  
  .chat-input-area:focus{
    outline: none;
  }
  
  .text-area{
    border: solid 2px #093C5D;
    border-radius: 10px;
    font-family: co-arabic;
    font-size: 20px;
    margin: 30px;
    width: 40vw;
    padding: 10px;
    line-height: 22px;
  }
  
  textarea::placeholder {
    color: #464646;
    font-family: patrick hand;
    font-size: 25px;
  }

  .chat-input-area::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Set the background color of the scrollbar track */
  .chat-input-area::-webkit-scrollbar-track {
    background-color: #fffdfd00;
  }
  
  /* Set the radius of the scrollbar thumb */
  .chat-input-area::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #093C5D;
  }
  