.demo-strategy {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: solid rgb(151, 151, 151) 1px;
    border-radius: 25px;
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
    opacity: 0;
    transition: all 2s ease-in-out;
}

.demo-strategy.show {
    opacity: 1;
}

.demo-strategy-whiteboardtitle {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: permanent marker;
}

.demo-strategy-section {
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.demo-strategy-block {
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}