.demo-main {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.demo-header {
    height: 25%;
    width: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
    top: 0%;
    opacity: 0;
    transition: opacity 1s ease-in-out, width 1s ease-in-out;
}

.show-demo-header {
    opacity: 1;
    width: 100%;
}

.demo-component {
    height: 70%;
    width: 90%;
    position: absolute;
    bottom: 5%;
    opacity: 0;
    transition: all 2s ease-in-out;
}

.show-demo-component {
    opacity: 1;
}

.shrink-demo-component {
    opacity: 0;
    transform: scale(0.1)
}

.demo-step {
    height: 150px;
    width: 90%;
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 25px;
}

.show {
    opacity: 1;
    transform: translateX(0%);
}

.appear {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0s ease-in-out, transform 0s ease-in-out;
}

.hide {
    opacity: 0;
}

.circle-and-move {
    transform: translateY(-50%) ;
    transition: transform 1s ease-in-out, 
}

.demo-header-text {
    font-size: 40px;
}
