
.side-menu-button-container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    width: 100%;
    height: 50%;
    margin-top: 10%;
    position: relative;
}

.side-menu-nav-option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 15%;
    width: 100%;
}

.nav-option-icon {
    background-image: url('../../../public/Assets/nav-button.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    box-shadow: none;
    border: none;
    height: 100%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
}

.nav-option-icon:hover {
    background-image: url('../../../public/Assets/nav-button-hover.png');
    
}

.nav-option-icon:active {
    transform: scale(1.1);
}

.nav-option-icon.selected {
    background-image: url('../../../public/Assets/nav-button-selected.png');
}

.nav-option-info {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    overflow: hidden;
}

.nav-option-checkbox {
    height: 50px;
    aspect-ratio: 1 / 1;
    background-image: url('../../../public/Assets/checked.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.nav-option-blank {
    height: 50px;
    width: 50px;
}