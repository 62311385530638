.cd-marker-navigation-container {
    position: absolute;
    bottom: 2%;
    left: 2%;
    height: 250px;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.cd-marker-holder {
    z-index: 2;
    position: absolute;
    bottom: 5px;
    background-image: url('../../assets/markerholder.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 310px;
}

.cd-marker-holder-back {
    z-index: 0;
    position: absolute;
    background-image: url('../../assets/markerholder-back.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 0;
    height: 280px;
    width: 290px;
}

.cd-marker-navigation-option {
    height: 150px;
    width: 70px;
    display: flex;
    flex-direction: row;
}

.cd-marker {
    z-index: 1;
    background-image: url('../../assets/marker-blue.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.cd-marker:hover {
    background-image: url('../../assets/marker-blue-hover.png');
    cursor: pointer;
}

.cd-marker.active {
    background-image: url('../../assets/marker-blue-active.png');
    cursor: default;
}

.cd-marker-label {
    z-index: 3;
    position: absolute;
    bottom: 50px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 30px;
}

.cd-body {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cd-body-display {
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 100px;
    position: relative;
}

.cd-body-input {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 450px;
    margin-right: 5%;
}

.cd-body-input.full {
    width: 80%;
    margin-left: 10%;
}

.cd-input-box {
    height: 180px;
    width: 100%;
    border: solid 3px #093C5D;
    border-radius: 10px;
    resize: none;
    font-family: patrick hand;
    font-size: 20px;
}

.cd-input-button-container {
    height: 50px;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.cd-display-graphic {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
}

.cd-display-graphic.avatarx {
    background-image: url('../../assets/VC-avatar-tablet.png');
    height: 200px;
    width: 300px;
    align-self: end;
}

.cd-display-graphic.messagex {
    background-image: url('../../assets/VC-message-bubble.png');
    background-position-x: 0;
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cd-display-graphic.messagel {
    background-image: url('../../assets/VC-message-bubble-large.png');
    background-position-x: 0;
    height: 200px;
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cd-message {
    height: 150px;
    width: 620px;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: patrick hand;
    position: relative;
}

.cd-message-waiting {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100px;
}