.interviews-main {
    height: 100%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.interviews-header{
    background-color: white;
    height: 10%;
    width: 80%;
    margin-top: 5%;
    border: 1px solid #093C5D;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.interviews-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 40%;
    width: 100%;
    margin-top: 25px;
}

.interviews-group-card {
    border: solid rgb(202, 202, 202) 1px;
    background-image: url('../../assets/culture-interviews.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-size: 90%;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 35vh;
    aspect-ratio: 4/5;
    position: relative;
    cursor: pointer;
}

.interviews-group-card:hover {
    background-color: #e9e9e9;
}

.interviews-group-card.strategicanalysis {
    background-image: url('../../assets/analysis-interviews.png');
}

.interviews-group-card.foundation {
    background-image: url('../../assets/foundation-interviews.png');
}

.interviews-group-card.focus {
    background-image: url('../../assets/focus-interviews.png');
}

.interviews-group-card.comprehensive {
    background-image: url('../../assets/comprehensive-interviews.png');
}

.interviews-group-card-name {
    position: absolute;
    top: 5%; left: 10%;
    color: white;
    width: 55%;
    height: 30%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.interviews-group-card-name.comprehensive {
    width: 80%;
}

.interviews-group-card-key {
    position: absolute;
    top: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.interviews-options-card {
    background-image: url('../../assets/option-card-A.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 25px;
    font-family: patrick hand;
    cursor: pointer;
}

.interviews-options-card.b {
    background-image: url('../../assets/option-card-B.png');
}

.interviews-options-card:hover {
    background-image: url('../../assets/option-card-A-hover.png');
}

.interviews-options-card.b:hover {
    background-image: url('../../assets/option-card-B-hover.png');
}

.interviews-options-card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    margin-Left: 15%;
    margin-right: 5%;
    margin-top: 5%;
    font-size: 20px;
    line-height: 25px;
    overflow: hidden;
}

.interviews-create{
    display: flex;
    justify-content: center;
    align-items: center;
}

.interviews-body{
    background-color: white;
    height: 70%;
    width: 80%;
    margin-top: 5%;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.interviews-body-header {
    color: black;
    width: 95%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 0 0 0%;
    position: relative;
}

.interviews-body-text {
    color: black;
    display: flex;
    justify-content: center;
    font-family: patrick hand;
    height: 50%;
    width: 100%;
    padding: 5%;
}

.interviews-button-container {
    position: absolute;
    right: 5%;
    z-index: 5;
}

.key {
    background-color: #1CA287;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.key:hover {
    background-color: #3391CE;
}

.interviews-tabs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffffff;
    width: 200px;
    height: 34px;
    border-radius: 5px;
}

.interviews-tab {
    height: 30px;
    width: 90px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 5px;
    color: #5a5a5a;
}

.interviews-tab:hover {
    color: rgb(74, 74, 74);
    font-weight: bold;
    cursor: pointer;
}

.interviews-tab.active {
    background-color: #3391CE;
    box-shadow: 1px 1px 5px 0 black;
    color: white;
}

.interviews-list {
    width: 95%;
    height: 86%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 3%;
}

.interviews-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .interviews-list::-webkit-scrollbar-track {
    background-color: #95959500;
  }
  
  .interviews-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #093b5d36;
  }

.interview-item {
    height: 50px;
    width: auto;
    margin: 5px 0 0 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    position: relative;
}

.interview-item:hover {
    background-color: #1CA287;
    color: white;
    cursor: pointer;
}

.tooltip-container::before {
    content: 'Click on the interview for a detailed view and summary'; 
    display: none;
    background-color: #1CA287;
    color: white;
    height: 100%;
    width: 30vw;
    border-radius: 5px;
    font-family: co-arabic;
    font-size: 15px;
    position: absolute;
    top: 0; 
    right: 0;
    z-index: 10;
  }
  
  .interview-item:hover .tooltip-container::before {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.interview-item-name {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    color: #093C5D;
}

.interview-item-name.status {
    width: 50%;
}

.interview-item-name.container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.interview-item-name-button {
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.interview-item-name.item {
    font-family: patrick hand;
    font-size: 25px;
    color: black;
}

.interview-item-name.center {
    justify: 10%;
}

.interview-item-name.info{
    position: absolute;
    right: 0;
    width: 45%;
    background-color: inherit;
    color: transparent;
}

.interview-item-name.left {
    position: absolute;
    left: 10%;
    width: auto;
}

.interview-item-name.right {
    position: absolute;
    right: 15%;
    width: auto;
}

.interview-item-name.select {
    width: 40%;
    border: none;
    background-color: transparent;
    color: #093C5D;
    justify-content: center;
}

.interview-item-checkbox {
    height: 50%;
    aspect-ratio: 1/1;
}

.interview-message {
    border-radius: 5px;
    margin: 1% 4% 1% 4%;
    padding: 10px;
    background-color: #ffffff;
    white-space: pre-line;
    padding: 10px;
    text-align: left;
    font-family: patrick hand;
    font-size: 20px;
    color: black;
}

.interview-message.user {
    background-color: #c0c0c0;
}

.interview-summary {
    white-space: pre-line;
    margin-left: 3%;
    height: 90%;
    width: auto;
    padding: 10px;
    text-align: left;
    font-family: patrick hand;
    font-size: 20px;
    color: black;
}

.interview-item-checkbox {
    display: none;
}

.interview-item-checkbox + label {
    cursor: pointer;
    display: inline-block;
    background: url('../../../../public/Assets/box_unchecked.png') no-repeat;
    background-size: contain;
    background-position: center;  
    height: 70%;
    aspect-ratio: 1/1; 
}

.interview-item-checkbox:checked + label {
    background: url('../../../../public/Assets/box_checked.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.interview-selection-box {
    background-color: #093C5D;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 50px;
    width: 300px;
    border: solid 2px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.interview-selection-box:hover {
    background-color: none;
}

.interview-selection-box.disabled {
    background-color: #345f7b;
    cursor: default;
}

.interview-selection-arrow {
    background-image: url('../../../../public/Assets/selection-arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 75%;
    aspect-ratio: 1 / 1;
  }
  
  .interview-selection-arrow.rotated {
    transform: rotate(180deg);
  }

  .interview-menu-item {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 5px; margin-top: 5px;
  }

    .interview-menu-item:hover {
        background-color: #184665;
        color: white;
        cursor: pointer;
    }

    .interview-menu-item.disabled:hover {
        background-color: #345f7b;
        cursor: default
    }