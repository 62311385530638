.analysis-dashboard {
    display: flex;
    height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

.analysis-dashboard-lens {
    left: 0;
    display: flex;
    justify-content: center;
    width: 50%;
}

.analysis-dashboard-card {
    
    height: 90%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 40px;
    border: solid white;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
}

.analysis-dashboard-table-header {
    font-size: 30px;
}

.analysis-dashboard-table {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.analysis-examination-list-item {
    font-size: 20px;
    cursor: pointer;
    text-align: left;
    width: 100%;
    padding-left: 50px;
}

.analysis-examination-list-item:hover {
    background-color: #093b5d80;
    width: 100%;
}

.analysis-examination-result {
    color: black;
    font-family: patrick hand;
    font-size: 20px;
    white-space: break-spaces;
    text-align: left;
    width: 90%;
    height: 90%;
    margin-bottom: 5px;
    overflow-y: auto;
}

.analysis-examination-result::-webkit-scrollbar {
  width: 8px;
}
  
.analysis-examination-result::-webkit-scrollbar-track {
  background-color: #95959500;
}
  
.analysis-examination-result::-webkit-scrollbar-thumb {
  background-color: #093b5d65;
}