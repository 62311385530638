.roots-main {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #093C5D;
}

.roots-tile-section {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px;
}

.roots-add-container {
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.roots-add-button {
    background-image: url('../assets/add-rootgroup-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.roots-add-text {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roots-tile-container {
    height: 100%;
    width: calc(100% - 200px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
}

.roots-tile-container::-webkit-scrollbar {
    height: 8px; /* Adjust height for horizontal scrollbar */
}

.roots-tile-container::-webkit-scrollbar-thumb {
    background: #3030307e;
    border-radius: 5px;
}

.roots-tile {
    background-image: url('../assets/root-tile-darkblue.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 0;
    height: 150px;
    margin-right: 15px;
    aspect-ratio: 1/.7;
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    cursor: pointer;
}

.roots-tile.active {
    background-image: url('../assets/root-tile-lightblue.png');
}



.roots-details-container {
    height: 100%;
    width: calc(100% - 60px);
    background-color: white;
    border: none;
    border-radius: 20px;
    box-shadow: 2px 3px 10px 3px rgba(0, 0, 0, 0.467);
    margin: 0px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.roots-table-menu {
    position: absolute;
    top: 20px; right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.roots-details-header {
    color: #093C5D;
    font-size: 40px;
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.roots-details-header-input {
    text-align: center;
}

.roots-details-header-input.saved {
    border: none;
    cursor: pointer;
}

.roots-details-header-input.saved:hover {
    text-decoration: underline;
}

.roots-details-header-input.saved:focus {
    cursor: text;
}

.roots-details-upper {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
}

.roots-group-user-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.roots-group-user-name {
    margin-left: 5px;
    cursor: pointer;
}

.roots-details-lower {
    height: calc(100% - 175px);
    display: flex;

}

.roots-details-table {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.roots-details-table-header {
    font-size: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: tragic marker;
    font-size: 40px;
}

.roots-details-list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: patrick hand;
    font-size: 25px;
}

.roots-list-newItem {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
}

.roots-newItem-field {
    width: 70%;
    height: 40px;
}

.roots-newItem-button {
    background-image: url('../assets/add-item.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.roots-newItem-button.remove {
    background-image: url('../assets/remove-item.png');
}

.roots-list-dropdown {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    cursor: pointer;
}

.roots-list-dropdown.open {
    height: auto;
    max-height: 400px;
}

.roots-list-selector-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: solid black 1px;
}

.roots-list-selector-container:hover {
    background-color: #c4c5c5;
}

.roots-list-options-menu {
    border: solid black 1px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.roots-list-selection-category {

}

.roots-list-category-name {
    
}

.roots-list-selection-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
}

.roots-list-selection-item:hover {
    background-color: #c4c5c5;
}

.roots-list-selection-arrow {
    background-image: url('../assets/dropdown-arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
    width: 30px;
    margin-left: 20px;
}

.roots-list-selection-arrow.rotated {
    transform: rotate(180deg);
}

.roots-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    padding-left: 20px;
}

.roots-table-graphic-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.roots-table-graphic-button.bullet {
    cursor: default;
}

.roots-table-graphic-button.addItem {
    margin-left: 10px;
}

.roots-table-graphic-button.adduser {
    align-items: flex-start;
    width: 150px;
    font-size: 15px;
    margin-left: 10px;
}

.roots-list-item-name {
    cursor: pointer;
}

.roots-list-item-name:hover {
    text-decoration: underline;
}


.roots-sidepanel-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.roots-sidepanel-header {
    font-size: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.roots-sidepanel-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 25px;
}

.roots-sidepanel-section-item {
    font-size: 25px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}