.user-management-column-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    font-weight: bold;
    font-size: 25px;
    width: calc(95% - 20px);
    height: 50px;
}

.user-management-column-heading {
    display: flex;
    justify-content: flex-start;
    width: 20%;
    margin-left: 5px;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: co-arabic;
}

.user-management-column-heading.email {
    width: 40%;
}

.user-management-table {
    height: calc(100% - 75px);
    width: calc(95% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    border-radius: 0px;
}

.user-management-table::-webkit-scrollbar {
    width: 5px;
}
  
.user-management-table::-webkit-scrollbar-track {
    background-color: #9595955d;
}
  
.user-management-table::-webkit-scrollbar-thumb {
    background-color: #093C5D;
}

.user-management-userList-item {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
}

.user-management-userList-item:hover {
    background-color: #093b5daa;
    color: white;
}

.user-management-userList-item.selected {
    background-color: transparent;
    color: black;
}

.user-management-userList-item.selected:hover {
    background-color: transparent;
    color: black;
}

.user-management-detail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    height: 100%;
    font-family: patrick hand;
    margin-left: 5px;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.user-management-detail.email {
    width: 40%;
    cursor: default;
}

.user-management-detail-input {
    width: 100%;
    height: 40px;
}

.user-management-detail-menu {
    overflow: visible;
    width: 20%;
    background-color: white;
    z-index: 2;
}

.user-detail-selectionBox {
    background-color: white;
    width: 90%;
    border: solid black 1px;
    box-shadow: 2px 3px 6px 0px #5f5f5f;
    font-family: co-arabic;
    font-size: 20px;
}

.user-detail-selectionBox-option {
    display: flex;
    justify-content: flex-start;
    padding-left: 5px;
    align-items: center;
    height: 40px;
    width: 100%;
    cursor: pointer;
}

.user-detail-selectionBox-option:hover {
    background-color: #093b5daa;
    color: white;
}

.user-management-inLine-buttonContainer{
    display: none;
}

.user-management-userList-item:hover .user-management-inLine-buttonContainer{
    display: flex;
}

.user-management-item-button {
    background-image: url('../assets/add-item.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.user-management-item-button.remove {
    background-image: url('../assets/remove-item.png');
}

.user-management-item-button.edit {
    background-image: url('../assets/remove-item.png');
}
