.message-bubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f1f0f0;
    padding: 20px;
    border: solid 2px #1CA287;
    border-radius: 25px;
    max-width: 20%;
    position: absolute;
    bottom: 20%; left: 40%;
    margin-left: auto;
    margin-right: auto;
    color: #494949;
    z-index: 5;
  }
  
  .dot {
    height: 15px;
    width: 15px;
    background-color: #1CA287;
    border-radius: 50%;
    margin-right: 5px;
    animation: dot-pulse 1s ease-in-out infinite;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dot-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .message-bubble p {
    font-size: 1.2rem;
    margin: 0;
  }
  