.settings-window {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: #093C5D;
    color: black;
    display: flex;
    flex-direction: column;
  }
  

.settings-header{
    background-color: #093C5D;
    text-align: center;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-weight: 600;
    width: 100%;
    height: 10%;
}

.settings-header-item {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-subheader{
    display: flex;
    flex-direction: row;
    background-color: #093C5D;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    justify-content: center;
    align-items: center;
    height: 10%;
    z-index: 2;
    position: relative;
}

.settings-tool {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 250px;
    padding: 5px;
}

.settings-tool.active {
    background-color: #1CA287;
    color: white;
}

.settings-tool.active:hover {
    background-color: #1CA287;
    color: white;
}

.settings-container{
    text-align: left;
    padding: 10px;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.settings-templatebuilder{
    display: flex;
    height: 100%;
    padding: 50px;
    background-color: white;
}

.settings-template-options {
    display: flex;
    flex-direction: column;
    border-right: solid white 2px;
    border-left: solid white 2px;
    justify-content: flex-start;
    padding:5px;
    margin-left: 20px;
    height: 100%;
}

.settings-template-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.settings-instructions::-webkit-scrollbar {
    width: 0.2em;
    background-color: #f5f5f500;
}
 
.settings-instructions::-webkit-scrollbar-thumb {
  background-color: #ffffff00;
}

.settings-template-buttons {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.template-body {
    height: 100%;
}

.template-area {
    display: flex;
    flex-direction: column;
    margin: 25px;
    width: 80%;
    height: 50%;
    text-align: left;
    color: #093C5D;
}

.my-select {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .settings-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    overflow: hidden;
  }

  .settings-table-header {
    height: 50px;
    width: 100%;
    padding: 10px;
    font-size: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .settings-tables-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 95%;
  }

  .settings-table {
    background-color: #093C5D;
    border: solid black 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 48%;
    width: 100%;
  }

  .settings-column-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 25px;
    color: white;
    width: 100%;
    height: 60px;
    position: relative;
  }

  .settings-details-table {
    border: solid black 2px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 40%;
  }

  .settings-list {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }

  .settings-list::-webkit-scrollbar {
    width: 0.2em;
    background-color: #e1e1e1;
}
 
.settings-list::-webkit-scrollbar-thumb {
  background-color: #093C5D;
}

  .settings-list-item {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 0px;
    padding-left: 5px;
    padding-right: 5px;
    height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .settings-list-item.button {
    background: none;
    color: black;
    border-radius: 0px;
    box-shadow: none;
  }

  .settings-list-item.button.selected {
    background: #3391CE;
    color: black;
    border: none;
    box-shadow: none;
}

  .settings-list-item.button:hover {
    background: #BFBFBF;
    color: black;
    border: none;
    box-shadow: none;
  }

  .settings-list-item.button.selected:hover {
    background: #3391CE;
    color: black;
    border: none;
    box-shadow: none;
  }

  .settings-list-item-detail {
    display: flex;
    justify-content: flex-start;
    width: 30%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .settings-list-item-detail.delete {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .settings-list-item-detail.delete:hover {

  }

  .settings-message-object {
    border: solid black;
  }

  .settings-instructions{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
}

  .instruction{
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 500px;
  }
  
  .instruction-text {
    margin-top: 15px;
    border: solid black 1px;
    width: 600px;
    font-size: 15px;
    word-wrap: break-word;
    vertical-align: top;
  }

  .instruction-text.prompt {
    width: 70%;
  }

  .settings-template-success {
    position: absolute;
    bottom: 0;
    color:#093C5D;
    font-family: patrick hand;
    font-size: 40px;
  }

  .settings-clear {
    cursor: pointer;
    background: none;
    box-shadow: none;
    border: none;
    color: white;
  }

  .settings-clear:hover {
    color: #093C5D;
  }

  .settings-prompts {
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 50px;
    overflow: hidden;
  }

  .settings-prompts-section {
    display: flex;
    height: 90%;
    flex-direction: row;
  }

  .settings-prompts-section::-webkit-scrollbar {
    width: 0.2em;
    background-color: #e1e1e1;
}
 
.settings-prompts-section::-webkit-scrollbar-thumb {
  background-color: #093C5D;
}