.meetingPanel {
    height: calc(100% - 100px);
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.meetingPanel-footer {
    width: 100%;
    height: 50px;
}

.meetingPanel-tabs {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.meetingPanel-tab {
    border: solid black 1px;
    border-radius: 10px 10px 0px 0px;
    width: 100px;
    height: 30px;
    margin-left: 1px;
    margin-right: 1px;
    font-size: 18px;
    background-color: #3391CE;
    color: black;
    cursor: pointer;
}

.meetingPanel-tab:hover {
    height: 35px;
}

.meetingPanel-tab.active {
    background-color: white;
    border-bottom: white;
    color: #093C5D;
    height: 35px
}

.meetingPanel-tab.inactive {
    display: none;
}

.meetingPanel-box {
    border: solid;
    border-radius: 10px;
    background-color: white;
    width: calc(100% - 50px);
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.meetingPanel-meeting-name {
    color: #093C5D;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px;
}

.meetingPanel-meeting-name.tab {
    height: 45px;
    width: 100%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.meetingPanel-meeting-name.click {
    cursor: pointer;
}

.meetingPanel-meeting-name.click:hover {
    text-decoration: underline;
}

.meetingPanel-meeting-name-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    color: black;
}

.meetingPanel-content-box {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
}

.meetingPanel-content-box::-webkit-scrollbar {
    width: 8px;
}
  
.meetingPanel-content-box::-webkit-scrollbar-track {
    background-color: #c5c5c500;
}
  
.meetingPanel-content-box::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #093b5d73;
}

.meetingPanel-agenda-item {
    width: calc(100%-5px);
    height: auto;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-right: 5px;
}

.meetingPanel-agenda-item-checkbox {
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.meetingPanel-agenda-item-info {
    width: calc(100% - 60px);
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.meetingPanel-agenda-item-name {
    width: 100%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.meetingPanel-agenda-item-description {
    width: 100%;
    height: calc(100%-30px);
    font-size: 15px;
    text-align: left;
}

.meetingPanel-notes-box {
    color: black;
    border: solid black 1px;
    height: calc(100% - 100px);
    width: 90%;
    text-align: left;
    font-family: patrick hand;
    resize: none;
    padding: 10px;
    line-height: 20px;
    overflow: auto;
}

.meetingPanel-notes-box::-webkit-scrollbar {
    width: 8px;
}
  
.meetingPanel-notes-box::-webkit-scrollbar-track {
    background-color: #c5c5c500;
}
  
.meetingPanel-notes-box::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #093b5d73;
}

.meetingPanel-date-picker {
    width: 100px;
    font-size: 15px;
    border-radius: none;
}

.meetingPanel-meeting-item {
    color: black;
}

.meetingPanel-new-meeting {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.meetingPanel-new-meeting-text {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    color: #093C5D;
    text-decoration: underline;
}

.meetingPanel-new-meeting-text:hover {
    color: #3391CE;
}

.meetingPanel-new-meeting-button {
    background-image: url('../assets/add-plus-small.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.meetingPanel-meeting-item-container {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.meetingPanel-meeting-item {
    width: calc(100% - 80px);
    cursor: pointer;
}

.meetingPanel-meeting-item-container:hover {
    background-color: #7abcbc;
}

.meetingPanel-meeting-item-name {

}



.meetingPanel-meeting-item-date {
    font-size: 15px;
}

.meetingPanel-item-button-container {
    display: none;
    position: absolute;
    right: 0px;
    z-index: 2;
}

.meetingPanel-notes-button-container {
    position: absolute;
    right: 50px;
    bottom: 0px;
    z-index: 2;
}

.meetingPanel-meeting-item-container:hover .meetingPanel-item-button-container {
    display: block;
}

.meetingPanel-item-button {
    background-image: url('../assets/add-item.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.meetingPanel-item-button.remove {
    background-image: url('../assets/remove-item.png');
}

.meetingPanel-assist-link {
    color: #093C5D;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px;
}

.meetingPanel-assist-link:hover {
    color: #3391CE;
}